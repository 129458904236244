import React, { useRef, FC } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
  content: string;
  onChange: (value: string) => void;
}

const TinyEditorWrapper: FC<Props> = ({ content, onChange }) => {
  const editorRef = useRef(null);

  return (
    <>
      <Editor
        tinymceScriptSrc={`${process.env.REACT_APP_DOMAIN}/tinymce/tinymce.min.js`}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onEditorChange={onChange}
        value={content}
        init={{
          height: 500,
          menubar: false,
          plugins: 'lists',
          branding: false,
          allow_html_in_named_anchor: true,
          toolbar1: 'cut copy paste pastetext | undo redo ',
          toolbar2:
            'bold italic underline strikethrough subscript superscript | removeformat | numlist bullist | indent outdent',
        }}
      />
    </>
  );
};

export default TinyEditorWrapper;
