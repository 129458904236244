import { IUserData } from 'src/types';
import React, { FC } from 'react';

interface Props {
  userData: IUserData;
}

const UserInfoBox: FC<Props> = ({ userData }) => {
  const { first_name, last_name, username, email } = userData;

  return (
    <div className="user-data-box">
      <div className="user-data-box-name">
        <div>{`${first_name} ${last_name}`}</div>
        <div>{username}</div>
      </div>
      <div className="user-data-box-email">{email}</div>
    </div>
  );
};

export default UserInfoBox;
