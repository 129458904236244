import { iconWarningSolid } from '@wfp/icons';
import { Icon, Link, Loading } from '@wfp/ui';
import get from 'lodash/get';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { requestAPI } from 'src/redux/actions';
import { GET_APP_CONFIGURATION } from 'src/redux/report/constants';
import { getReportListUrl } from 'src/utils';
import Breadcrumb from './Breadcrumb';
import Footer from './Footer';
import Header from './Header';

export const NoAccess = () => {
  return (
    <main className="not-authorized">
      <Icon
        icon={iconWarningSolid}
        fill="#0a6eb4"
        width={100}
        height={100}
        style={{ marginBottom: '3rem' }}
      />
      <h1 className="wfp--story__title">No access</h1>
      <p>
        Sorry, you don&apos;t have permission to view this page.
        <br />
        Please, send an email to{' '}
        <Link href="mailto:hq.spring@wfp.org">hq.spring@wfp.org</Link> to
        request access.
      </p>
    </main>
  );
};

export class BaseLayoutComponent extends Component {
  appStatuses = {
    pending: 'pending',
    needsInit: 'needsInit',
    ready: 'ready',
  };

  constructor(props) {
    super(props);

    this.initApp = this.initApp.bind(this);
    this.configureApp = this.configureApp.bind(this);
    this.state = {
      appStatus: this.appStatuses.pending,
      data: null,
    };
  }

  componentDidMount() {
    const { API: API = {}, ...rest } = window.djconfig;
    window.djconfig = {
      APIS: {
        GET_APP_CONFIGURATION: `/acr/frontend-api/app-conf/`,
        ...API,
      },
      ...rest,
    };

    if (this.state.appStatus === this.appStatuses.pending) {
      if (!this.isAppConfLoaded()) {
        this.setState({ appStatus: this.appStatuses.needsInit });
      } else {
        this.setState({ appStatus: this.appStatuses.ready });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.appStatus === this.appStatuses.needsInit &&
      prevState.appStatus !== this.state.appStatus
    ) {
      this.props.fetchAppConf();
    }
    if (!!prevProps.isFetching && !this.props.isFetching) {
      if (this.isAppConfLoaded()) {
        const { data = {} } = this.props;
        this.setState({ data }, this.configureApp);
      } else {
        this.setState({ appStatus: this.appStatuses.needsInit });
      }
    }
  }

  initApp = () => {
    this.setState({ needsInitialization: false }, this.props.fetchAppConf);
  };

  configureApp = () => {
    const { APIS: APIS_djconfig = {}, ...rest_djconfig } = window.djconfig;
    const { APIS: APIS_loaded = {}, ...rest_loaded } = this.props.data;

    window.djconfig = {
      APIS: {
        ...APIS_djconfig,
        ...APIS_loaded,
      },
      ...rest_djconfig,
      ...rest_loaded,
    };
    this.setState({ appStatus: this.appStatuses.ready });
  };

  isAppConfLoaded = () => {
    return !this.props.error && !this.props.isFetching && !!this.props.data;
  };

  render() {
    let content;
    let userInfo = {};
    let hasAnyPermission = false;
    let { data } = this.props;
    if (data === null) {
      data = this.state.data;
    }
    if (data) {
      userInfo = get(data, 'userInfo', {});
      if (userInfo) {
        hasAnyPermission = get(userInfo, 'hasAnyPermission', false);
      }
    }

    if (this.state.appStatus !== this.appStatuses.ready) {
      content = <Loading withOverlay={true} className="page-loader" />;
    } else if (!hasAnyPermission) {
      content = <></>;
      content = <NoAccess />;
    } else {
      const routesComponents = [];
      const makeRoutes = (routes, keyPrefix = '') => {
        for (const [routeKey, subRoute] of Object.entries(routes)) {
          const recursiveKeyPrefix = `${keyPrefix}-${routeKey}`;

          if (subRoute.hasOwnProperty('path')) {
            routesComponents.push(
              <Route
                key={recursiveKeyPrefix}
                path={subRoute.path}
                exact={true}
                component={subRoute.component}
              />,
            );
          }

          if (subRoute.hasOwnProperty('sub_routes')) {
            makeRoutes(subRoute.sub_routes, recursiveKeyPrefix);
          }
        }
      };
      makeRoutes(this.props.routesConfig.root.sub_routes);
      const redirectTo = getReportListUrl(
        this.props.routesConfig.root.sub_routes.reports_dashboard.path,
        this.props?.data?.currentPeriod,
      );

      content = (
        <React.Fragment>
          <Breadcrumb />
          <Switch>
            {routesComponents}
            <Redirect
              from={`${this.props.routesConfig.root.path}*`}
              to={redirectTo}
            />
          </Switch>
        </React.Fragment>
      );
    }

    return (
      <div className="page-content">
        <div className="main-footer-padding">
          <Header routesConfig={this.props.routesConfig} />
          {content}
        </div>
        <Footer />
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    routesConfig: state.routesConfig,
    data: null,
    isFetching: false,
    error: null,
    ...state.viewData[GET_APP_CONFIGURATION],
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    fetchAppConf: () => {
      dispatch(requestAPI(GET_APP_CONFIGURATION));
    },
  };
};

export const BaseLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseLayoutComponent);
