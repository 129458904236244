import React, { FC, useState } from 'react';
import { Button, Loading, ContextMenu, Icon } from '@wfp/ui';
import { apiCallAsync } from 'src/redux/sagas';
import { API_HOST } from 'src/constants';
import { iconChevronDown } from '@wfp/icons';
import { showNotification } from 'src/utils';
import NotificationMessage from './NotificationMessage';
import ContextualMenu from './ContextualMenu';
import { gaParamPosition } from 'src/types';

interface Props {
  period: string;
  projectCode: string;
  gaPosition: gaParamPosition;
  sectionId?: number;
}

interface ExportDocRequestPayload {
  comments_only: boolean;
  narratives_only: boolean;
  section_id?: number;
}

const IssuesDownloadDocButton: FC<Props> = ({
  period,
  projectCode,
  gaPosition,
  sectionId,
}) => {
  const [loadingFile, setLoadingFile] = useState(false);

  function exportContent(commentsOnly: boolean, narrativesOnly: boolean) {
    if (!period || !projectCode) return;

    const exportUrl =
      API_HOST + `/acr-api/issues-overview/${period}-${projectCode}/`;
    const data: ExportDocRequestPayload = {
      comments_only: commentsOnly,
      narratives_only: narrativesOnly,
    };
    if (sectionId) {
      data.section_id = sectionId;
    }
    let gaParam = 'narratives_and_comments';
    if (commentsOnly) {
      gaParam = 'comments';
    } else if (narrativesOnly) {
      gaParam = 'narratives';
    }

    setLoadingFile(true);
    apiCallAsync(exportUrl, data, 'post')
      .then(result => {
        if (result.status === 200) {
          showNotification(
            <NotificationMessage link={result.data.link} gaParam={gaParam} />,
            'success',
            {
              autoClose: false,
              closeOnClick: false,
            },
          );
        }
        setLoadingFile(false);
      })
      .catch(error => {
        console.log('error', error);
        setLoadingFile(false);
        showNotification('Error on .docx export', 'error');
      });
    return;
  }

  return (
    <>
      {loadingFile ? (
        <div className="issues-overview-page-download-btn">
          <Button kind="secondary">
            <Loading
              className="wfp--loading--small"
              withOverlay={false}
              active
            />
            <div>Preparing .docx</div>
          </Button>
        </div>
      ) : (
        <ContextMenu
          className="io-context-menu-wrapper"
          content={
            <ContextualMenu
              exportContent={exportContent}
              gaPosition={gaPosition}
            />
          }
        >
          <span className="wfp--btn wfp--btn--secondary noselect issue-download-docx-btn">
            Download as .docx
            <Icon icon={iconChevronDown} description="down-arrow" />
          </span>
        </ContextMenu>
      )}
    </>
  );
};

export default IssuesDownloadDocButton;
