import { Module, ModuleBody, ModuleHeader, Loading } from '@wfp/ui';
import { Infographics } from 'acr_ui/sources/components/Infographics';
import { WFPContributionToSDGs } from 'acr_ui/sources/components/WFPContributionToSDGs';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

import orderBy from 'lodash/orderBy';
import WordLimitPanel from 'src/components/ReportTemplate/Workspace/WordLimitPanel';
import BottomLinks from 'src/components/ReportTemplate/Workspace/BottomLinks';
import { DATA_KEY_ON_DATA_SET } from 'src/components/ReportTemplate/Workspace/config';
import DataNotes from 'src/components/ReportTemplate/Workspace/DataNotes';
import LatestRefreshInfo from 'src/components/ReportTemplate/Workspace/LatestRefreshInfo';
import Title from 'src/components/ReportTemplate/Workspace/Title';
import SectionControls from 'src/components/ReportTemplate/WorkspaceACR2023/SectionControls';
import Subtitle from 'src/components/ReportTemplate/WorkspaceACR2023/Subtitle';
import Narrative from 'src/components/ReportTemplate/WorkspaceACR2023/Narrative';
import ContactInfo from 'src/components/ReportTemplate/Workspace/ContactInfo';
import { useRefreshDataSource } from 'src/hooks';
import {
  requestAPI,
  requestAPIHoldingData,
  requestAPILatest,
} from 'src/redux/actions';
import {
  getIconsDetails,
  LockInfographicsAction,
  UnlockInfographicsAction,
  UpdateInfographicsAction,
} from 'src/redux/report/actions';
import {
  ICONS_DETAILS_KEY,
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
  SECTION_REFRESH_DATA_SOURCE_KEY,
  WORDCOUNT_CONFIG,
  GET_APP_CONFIGURATION,
} from 'src/redux/report/constants';
import { getReportPageTitle, getShowWordcount, sendGAEvent } from 'src/utils';
import {
  DATA_SOURCES_WIDGETS_MAP,
  getDataSourceWidgetsParametersMap,
} from './dataSourceWidgetsConfig';
import ImageUploader from 'src/components/ImageUploader';

interface ILastRefreshInfo {
  date: string;
  type: string;
  user: string;
}

type ILastRefreshInfoList = ILastRefreshInfo[];

interface IData {
  dataSource: string;
  lastRefreshInfo?: ILastRefreshInfoList[];
  containerClassName?: string;
  noModuleTitle?: any;
  component?: React.JSX.Element;
}

const WorkspaceACR2023 = () => {
  const dispatch = useDispatch();

  const reportDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY].data,
  );
  const reportDetailError = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY]?.error,
  );
  const sectionDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData?.[SECTION_DETAIL_DATA_KEY]?.data,
  );
  const sectionDetailError = useSelector(
    (state: RootStateOrAny) => state.viewData?.[SECTION_DETAIL_DATA_KEY]?.error,
  );
  const viewState = useSelector((state: RootStateOrAny) => state.viewState);
  const iconsDetailsData = useSelector(
    (state: RootStateOrAny) => state.viewData?.[ICONS_DETAILS_KEY]?.data,
  );
  const wordcountConfig = useSelector(
    (state: RootStateOrAny) => state.viewData?.[WORDCOUNT_CONFIG]?.data,
  );
  const dataSourceRefresh = useSelector(
    (state: RootStateOrAny) =>
      state.viewData?.[SECTION_REFRESH_DATA_SOURCE_KEY],
  );
  const userData = useSelector(
    (state: RootStateOrAny) => state.viewData?.[GET_APP_CONFIGURATION]?.data,
  );
  const currentUserUsername = userData.userInfo?.username;

  const [isEditInfographics, setIsEditInfographics] = useState(false);

  const [handleRefreshDataSources] = useRefreshDataSource();

  const isStrategicOutcome = sectionDetailData?.current_title
    .toLowerCase()
    .includes('strategic outcome');

  const headingWidgets = [],
    remainingWidgets = [];

  const createdDataSource = useMemo(() => {
    if (sectionDetailData?.data_sources) {
      return sectionDetailData?.data_sources.map(getComponentForDataSource);
    }

    return [];
  }, [sectionDetailData]);

  const headingWidgetNames = ['Strategic Outcome Title'];
  for (let i = 0, len = createdDataSource.length; i < len; i++) {
    const dataSourceObject = createdDataSource[i];
    if (headingWidgetNames.includes(dataSourceObject?.dataSource)) {
      headingWidgets.push(dataSourceObject);
    } else {
      remainingWidgets.push(dataSourceObject);
    }
  }

  const showWordcount = getShowWordcount(
    sectionDetailData?.current_title,
    reportDetailData?.permissions?.has_permission_to_read_word_count,
  );

  useEffect(() => {
    dispatch(getIconsDetails());
  }, []);

  useEffect(() => {
    if (
      sectionDetailData?.infographics_editor?.infographics_editor &&
      sectionDetailData.infographics_editor.infographics_editor ===
        currentUserUsername
    ) {
      setIsEditInfographics(true);
    }
  }, [sectionDetailData?.infographics_editor, currentUserUsername]);

  useEffect(() => {
    if (viewState?.activeSectionID) {
      dispatch(
        requestAPILatest(SECTION_DETAIL_DATA_KEY, null, {
          url_format: { _id_: viewState.activeSectionID },
        }),
      );

      getReportWordcountConfig();

      setIsEditInfographics(false);
    }
  }, [viewState?.activeSectionID]);

  useEffect(() => {
    // NEEDED ?
    if (!dataSourceRefresh?.error && reportDetailData.reportPk) {
      dispatch(
        requestAPI(REPORT_DETAIL_DATA_KEY, null, {
          url_format: { _id_: reportDetailData.reportPk },
        }),
      );
    }
  }, [dataSourceRefresh, reportDetailData]);

  function getReportWordcountConfig(additionalParams = {}) {
    const params = {
      url_format: { _id_: reportDetailData.pk },
      data: {
        section_id: viewState.activeSectionID,
      },
      ...additionalParams,
    };

    dispatch(
      requestAPIHoldingData(WORDCOUNT_CONFIG, reportDetailData.pk, params),
    );
  }

  function buildPropsForTotalBeneficiaries() {
    return {
      data:
        sectionDetailData?.datasets?.total_actual_beneficiaries ||
        sectionDetailData?.datasets?.total_adjusted_beneficiaries_age_group,
      period: reportDetailData.period,
    };
  }

  function getComponentForDataSource(dataSource: string) {
    const data: IData = {
      dataSource,
    };
    let PresentationComponent: any;

    const dataSourceSlug: string = DATA_KEY_ON_DATA_SET[dataSource];

    let dataFromBacked;

    if (Array.isArray(dataSourceSlug)) {
      data.lastRefreshInfo = [];
      dataFromBacked = {};

      for (const slug of dataSourceSlug) {
        dataFromBacked[slug] = sectionDetailData.datasets[slug];
        data.lastRefreshInfo.push(
          sectionDetailData.refresh_date
            ? sectionDetailData.refresh_date?.[slug]
            : null,
        );
      }
    } else {
      dataFromBacked = sectionDetailData.datasets[dataSourceSlug];
      data.lastRefreshInfo = [
        sectionDetailData.refresh_date
          ? sectionDetailData.refresh_date[dataSourceSlug]
          : null,
      ];
    }

    data.lastRefreshInfo = data.lastRefreshInfo.filter(x => !!x);

    let componentsData = [],
      widgetParameters;

    switch (dataSource) {
      case 'Total Beneficiaries':
        PresentationComponent = DATA_SOURCES_WIDGETS_MAP[dataSource];
        data.component = (
          <PresentationComponent {...buildPropsForTotalBeneficiaries()} />
        );
        break;
      case 'WFP contribution to SDGs':
        data.noModuleTitle = widgetParameters?.noModuleTitle;
        data.containerClassName = widgetParameters?.containerClassName || '';

        data.component = <WFPContributionToSDGs />;
        break;
      default:
        PresentationComponent = DATA_SOURCES_WIDGETS_MAP[dataSource];
        componentsData = dataFromBacked || [];
        widgetParameters = getDataSourceWidgetsParametersMap?.[dataSource];
        data.noModuleTitle = widgetParameters?.noModuleTitle;
        data.containerClassName = widgetParameters?.containerClassName || '';
        data.component = (
          <PresentationComponent
            data={componentsData}
            reportState={reportDetailData.state}
            report={reportDetailData}
            currentPeriod={reportDetailData.period}
            {...getDataSourceWidgetsParametersMap[dataSource]}
          />
        );
    }
    return data;
  }

  function customOverviewSubtitleAndNarratives() {
    const narratives = sectionDetailData?.narratives;
    const keyMessages = narratives?.find(
      ({ title }) => title === 'Key messages',
    );
    const main = narratives?.find(({ title }) => title === 'Main narrative');

    return (
      <>
        {keyMessages && (
          <Narrative
            narrative={keyMessages}
            isEditEnable={sectionDetailData.permissions.can_edit_narrative}
            isForcePostEnabled={
              sectionDetailData.permissions.can_force_post_narrative
            }
          />
        )}
        {sectionDetailData?.has_subtitle && (
          <Subtitle
            sectionId={viewState.activeSectionID}
            initialContent={sectionDetailData?.custom_subtitle}
            isEditable={
              sectionDetailData.is_content_editable &&
              reportDetailData.state !== 'Published'
            }
          />
        )}
        {main && (
          <Narrative
            narrative={main}
            isEditEnable={sectionDetailData.permissions.can_edit_narrative}
            isForcePostEnabled={
              sectionDetailData.permissions.can_force_post_narrative
            }
          />
        )}
      </>
    );
  }

  const sortedNarratives = orderBy(sectionDetailData?.narratives, 'order');
  const isOverviewPage = sectionDetailData?.current_title === 'Overview';

  return (
    <>
      <Helmet>
        <title>
          {getReportPageTitle(
            reportDetailData?.country,
            reportDetailData?.period,
          )}
        </title>
      </Helmet>
      <main
        className="section-detail new-crf-template"
        id="section-wrapper-main"
      >
        <SectionControls
          error={sectionDetailError}
          dataSourceRefresh={dataSourceRefresh}
          handleRefreshDataSources={handleRefreshDataSources}
        />
        {(!viewState || !reportDetailData || !sectionDetailData) && (
          <div className="section-loader-wrapper">
            <Loading className="section-loader" withOverlay={false} active />
          </div>
        )}
        {sectionDetailData?.has_infographics && (
          <Infographics
            isEdit={isEditInfographics}
            setEdit={(val: boolean) => setIsEditInfographics(val)}
            data={sectionDetailData.infographics_data?.widget_items}
            editorData={sectionDetailData.infographics_editor}
            currentUserUsername={currentUserUsername}
            canEditInfographics={
              reportDetailData.permissions?.infographics_permissions
                ?.edit_infographics
            }
            canUnlockInfographics={
              reportDetailData.permissions?.infographics_permissions
                ?.unlock_infographics
            }
            alignment={sectionDetailData.infographics_data?.alignment}
            renderTutorial={sectionDetailData.tutorialRender}
            saveAction={objToSave => {
              const payload = {
                method: 'post',
                data: {
                  ...objToSave,
                  section_id: viewState.activeSectionID,
                  widget_pk: sectionDetailData.infographics_data?.widget_items,
                },
              };
              dispatch(UpdateInfographicsAction(payload));
            }}
            lockAction={() => {
              const payload = {
                method: 'post',
                data: {
                  section_id: viewState.activeSectionID,
                },
              };
              dispatch(LockInfographicsAction(payload));
            }}
            unlockAction={() => {
              const payload = {
                method: 'post',
                data: {
                  section_id: viewState.activeSectionID,
                },
              };
              dispatch(UnlockInfographicsAction(payload));
            }}
            iconsList={iconsDetailsData}
            reportState={reportDetailData.state}
            domainLink={process.env.REACT_APP_DOMAIN}
            sendGAEvent={sendGAEvent}
          />
        )}
        {!!reportDetailData &&
          !!sectionDetailData?.image_attachment_type &&
          !isStrategicOutcome && <ImageUploader />}

        {!!reportDetailData && isOverviewPage && (
          <ContactInfo
            reportPk={reportDetailData.pk}
            reportIsPublished={reportDetailData.state === 'Published'}
          />
        )}

        {!!reportDetailData && !!sectionDetailData && (
          <Title
            initialContent={sectionDetailData?.current_title}
            isEditable={
              sectionDetailData?.can_change_title &&
              reportDetailData.state !== 'Published'
            }
            sectionId={viewState.activeSectionID}
            headingWidgets={headingWidgets}
          />
        )}

        {!!wordcountConfig && showWordcount && !wordcountConfig?.isFetching && (
          <Module className="section-heading">
            <ModuleBody>
              <WordLimitPanel
                data={wordcountConfig}
                dispatchWordcountConfig={getReportWordcountConfig}
                sectionID={viewState.activeSectionID}
                sectionTitle={sectionDetailData?.current_title}
                isEditable={
                  reportDetailData.state !== 'Published' &&
                  reportDetailData?.permissions
                    ?.has_permission_to_edit_word_count
                }
              />
            </ModuleBody>
          </Module>
        )}

        {isOverviewPage && customOverviewSubtitleAndNarratives()}

        {!isOverviewPage && sectionDetailData?.has_subtitle && (
          <Subtitle
            sectionId={viewState.activeSectionID}
            initialContent={sectionDetailData?.custom_subtitle}
            isEditable={
              sectionDetailData.is_content_editable &&
              reportDetailData.state !== 'Published'
            }
          />
        )}
        {!isOverviewPage &&
          sortedNarratives.map(narrative => (
            <Narrative
              key={narrative.id}
              narrative={narrative}
              isEditEnable={sectionDetailData.permissions.can_edit_narrative}
              isForcePostEnabled={
                sectionDetailData.permissions.can_force_post_narrative
              }
            />
          ))}

        {remainingWidgets.map((item, index) => {
          const finacialOverviewTitle =
            'Annual Financial Overview by Strategic Outcome and Activity';
          const isFinancialOverview =
            item?.dataSource === finacialOverviewTitle;

          const displayCertifiedSubtitle =
            parseInt(item?.component?.props?.report?.period) >= 2023 &&
            isFinancialOverview;
          const title = isFinancialOverview
            ? `${finacialOverviewTitle} (in USD)`
            : item?.dataSource;

          return (
            <Module
              key={index}
              data-test-id={item?.dataSource}
              className={`data-source restricted-chart-width ${
                item?.noModuleTitle ? 'special-module-title' : ''
              } ${item?.containerClassName}`}
            >
              {!item?.noModuleTitle && (
                <ModuleHeader
                  className={
                    displayCertifiedSubtitle ? 'with-certified-subtitle' : ''
                  }
                >
                  {title}
                  {displayCertifiedSubtitle && (
                    <div className="certified-subtitle">
                      {item?.component.props.data.certified === 'True'
                        ? 'Certified'
                        : 'Uncertified'}
                      {' version'}
                    </div>
                  )}
                </ModuleHeader>
              )}
              <ModuleBody>{item?.component}</ModuleBody>
              {item?.lastRefreshInfo &&
                item?.dataSource !== 'WFP contribution to SDGs' && (
                  <LatestRefreshInfo refreshInfo={item.lastRefreshInfo} />
                )}
            </Module>
          );
        })}
        <DataNotes />
        <BottomLinks
          report={reportDetailData}
          currentTitle={sectionDetailData?.current_title}
        />
        <hr className="line-break" />
      </main>
    </>
  );
};

export default WorkspaceACR2023;
