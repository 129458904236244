import { Footer as WFPFooter, Link } from '@wfp/ui';
import React from 'react';

import { sendGAEvent } from 'src/utils';

const Footer = () => {
  return (
    <WFPFooter>
      <div className="wfp--footer-info footer-link-container">
        <div className="wfp--footer-info__item">
          <Link
            href="https://www.wfp.org"
            target="_blank"
            data-test-id="wfp-org-link"
          >
            WFP.org
          </Link>
        </div>
        <div className="wfp--footer-info__item">
          <Link
            href="https://annualreports.wfp.org/"
            target="_blank"
            data-test-id="annual-country-reports-link"
          >
            Annual Country Reports
          </Link>
        </div>
        <div className="wfp--footer-info__item">
          <Link
            href="https://acr.manuals.wfp.org/en/"
            target="_blank"
            data-test-id="acr-guidance-link"
            onClick={() =>
              sendGAEvent('acr_guidance_link_clicked', {
                wfp_position: 'footer',
              })
            }
          >
            ACR Guidance
          </Link>
        </div>
      </div>
    </WFPFooter>
  );
};

export default Footer;
