import { iconChevronLeft, iconChevronRight, iconWarning } from '@wfp/icons';
import { Button, Icon, InfoBar, Modal } from '@wfp/ui';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { apiCallAsync } from 'src/redux/sagas';

import ConfirmationModal from 'src/components/ConfirmationModal';
import { TechnicalReview } from 'src/components/ReportTemplate/technicalReview';
import {
  backward,
  CURRENT_STATE_LABELS,
  forward,
  ICON_KWARGS,
  REVIEW_BUTTONS,
  TRANSITION_AIM,
  TRANSITION_DIRECTION,
  TRANSITION_LABELS,
  WEB_REP_HOST,
  API_HOST,
} from 'src/constants';
import { postAcrReportStateTransition } from 'src/redux/report/actions';
import {
  GET_ALL_REVIEW_STATE_DATA,
  POST_ACR_INFO_BOX_CONTENT_KEY,
  POST_ACR_REPORTS_STATE_TRANSITION_KEY,
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
  UPDATE_REVIEW_STATE_DATA_KEY,
} from 'src/redux/report/constants';
import { sendGAEvent, showNotification } from 'src/utils';
import PDFGeneratorButton from './PDFGeneratorButton';
import ReviewGroupModal from './ReviewGroupModal';

const pollingSecondsInterval = 10;

const HeaderComponent = props => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showReviewGroupModal, setShowReviewGroupModal] = useState(false);
  const [isReportPublishing, setIsReportPublishing] = useState(false);

  const reportAndFilesPublished =
    !isReportPublishing &&
    props.report_transition?.current_transition_state === 'Published';

  async function checkReportStatus() {
    const url = `${API_HOST}/api/acr-reports/_year_/_cpb_/${props.report_pk}/check_static_publishing/`;

    try {
      const res = await apiCallAsync(url, {});

      const status = res.data?.status;

      if (!status || status === 'published') {
        setIsReportPublishing(false);
      } else if (status === 'publishing') {
        setIsReportPublishing(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    checkReportStatus();

    const intId = isReportPublishing
      ? setInterval(() => {
          checkReportStatus();
        }, pollingSecondsInterval * 1000)
      : null;

    if (!isReportPublishing && intId) {
      clearInterval(intId);
    }

    return () => {
      if (intId) clearInterval(intId);
    };
  }, [isReportPublishing, props.report_transition.current_transition_state]);

  useEffect(() => {
    if (props.report_transition.error) {
      setShowErrorModal(true);
    }
  }, [props.report_transition.error]);

  function handleStateTransitionSubmit(state) {
    props.dispatchStateTransition({
      data: { state: TRANSITION_AIM[state] },
      url_format: {
        _id_: props.report_pk,
      },
    });
    setShowConfirmationModal(null);
  }

  function convertStatesToDisplay(states) {
    return states.map(item => {
      return {
        key: item,
        label: TRANSITION_LABELS[item] || REVIEW_BUTTONS[item] || item,
        direction: TRANSITION_DIRECTION[item],
      };
    });
  }

  function openWebRepPreview(code, year) {
    let url = `${WEB_REP_HOST}&operation_id=${code}&year=${year}`;
    if (reportAndFilesPublished) {
      url = url.replace('preview.', '');
    }
    const win = window.open(url, '_blank');
    win.focus();
  }

  function getErrorList() {
    const errorComponents = [];
    const errors = props.report_transition.error;
    for (const key of Object.keys(errors)) {
      const errorsForKey = errors[key];
      for (let i = 0, len = errorsForKey.length; i < len; i++) {
        errorComponents.push(
          <li key={i}>
            <div
              className="report-status-change-modal"
              dangerouslySetInnerHTML={{ __html: errorsForKey }}
            />
          </li>,
        );
      }
    }
    return errorComponents;
  }

  function buildReportButtons() {
    const reportsButtons = [];
    const availableStateTransitions = convertStatesToDisplay(
      props.report_transition.available_state_transitions,
    );
    const reviewButtons = convertStatesToDisplay(Object.keys(REVIEW_BUTTONS));
    // check report state buttons
    reportsButtons.push(
      availableStateTransitions.map(item => {
        const { key, direction, label } = item;

        const classes = `transition-action ${
          direction === forward ? 'wfp--btn--primary' : 'wfp--btn--secondary'
        } ${direction || ''}`;

        const buttonContent = (
          <>
            {direction === backward && (
              <Icon
                description="backward"
                icon={iconChevronLeft}
                {...ICON_KWARGS}
              />
            )}
            <span>{label}</span>
            {direction === forward && (
              <Icon
                description="forward"
                icon={iconChevronRight}
                {...ICON_KWARGS}
              />
            )}
          </>
        );

        return (
          <Button
            key={key}
            className={classes}
            data-test-id={`${key} button`}
            onClick={() =>
              setShowConfirmationModal({
                key,
                direction,
              })
            }
          >
            {buttonContent}
          </Button>
        );
      }),
    );

    if (
      props.report_transition.current_transition_state ===
      CURRENT_STATE_LABELS['review']
    ) {
      let dataTarget = '';
      reportsButtons.push(
        reviewButtons.map(item => {
          // set specific data-target for review buttons
          if (item.label === REVIEW_BUTTONS.set_review_status) {
            dataTarget = '#set-review-status-modal';
            return (
              <Button
                key="review-status-modal"
                className="wfp--btn--secondary"
                onClick={() => setShowReviewGroupModal(true)}
                data-test-id="set-review-status-button"
                disabled={props.updateReview?.isFetching}
              >
                Set review group
              </Button>
            );
          }
          if (item.label === REVIEW_BUTTONS.technical_review) {
            return (
              <TechnicalReview
                reportPk={props.report_pk}
                officeCode={props.officeCode}
                hasPermissionToMark
                key={item.key}
              />
            );
          }
          return (
            <Button data-toggle="modal" data-target={dataTarget} key={item.key}>
              {item.label}
            </Button>
          );
        }),
      );
    }
    return reportsButtons;
  }

  return (
    <>
      <header className="report-header main-side-padding">
        <section className="info" data-test-id="project-info">
          <span className="project-code" data-test-id="project-code">
            {props.project_code}
          </span>
          <span className="country-name" data-test-id="country-name">
            {props.country}
          </span>
          <span
            className="wfp--tag transition-state"
            data-test-id="report-state"
          >
            {props.report_transition.current_transition_state}
          </span>
          <br />
          <span className="year">
            <strong data-test-id="period-label">Period:</strong>{' '}
            {props.duration}
          </span>
        </section>
        <section className="actions actions-flex">
          <div>
            <div className="preview-btns">
              <Button
                kind="secondary"
                data-test-id="webReport-preview-button"
                onClick={() => {
                  sendGAEvent('web_report_preview_btn_clicked');
                  openWebRepPreview(props.project_code, props.period);
                }}
              >
                WEB Report {reportAndFilesPublished ? '' : 'Preview'}
              </Button>
              <PDFGeneratorButton />
            </div>
            <aside className="state-transitions">
              {props.report_transition.isFetching ? (
                <span>processing...</span>
              ) : (
                buildReportButtons()
              )}
            </aside>
          </div>
        </section>
      </header>
      <div
        className="cd-approval-warning"
        data-test-id="cd-approval-warning"
        style={{
          width: '100%',
          textAlign: 'right',
        }}
      >
        {props.report_transition.current_transition_state === 'CD Approval' && (
          <InfoBar>
            <Icon description="warning" icon={iconWarning} fill="#000000" />
            Dear Country Director and general users, please be aware that once
            the ACR is approved by the CD it will not be possible to make
            changes to the information in the data tables
          </InfoBar>
        )}
      </div>
      {showErrorModal && (
        <Modal
          open
          passiveModal
          type="danger"
          onRequestClose={() => setShowErrorModal(false)}
          modalHeading="Error while changing the status of the report"
          className="report-transition-errors"
        >
          <ul className="errors">{getErrorList()}</ul>
        </Modal>
      )}
      {showReviewGroupModal && (
        <ReviewGroupModal
          reportPk={props.report_pk}
          hasPermissionToModify={props.hasPermissionToModify}
          setShowModal={val => {
            setShowReviewGroupModal(val);
          }}
        />
      )}
      {showConfirmationModal !== null && (
        <ConfirmationModal
          open
          onSubmit={() =>
            handleStateTransitionSubmit(showConfirmationModal.key)
          }
          onClose={() => setShowConfirmationModal(null)}
          heading={`Do you really want to move this report ${
            showConfirmationModal.direction === backward ? 'back' : ''
          } to ${
            CURRENT_STATE_LABELS?.[TRANSITION_AIM[showConfirmationModal.key]]
          } status?`}
          primaryButtonText="Yes"
          secondaryButtonText="No"
        />
      )}
    </>
  );
};

export const mapStateToProps = state => {
  const neededData = {
      available_state_transitions: [],
      state: '',
      has_permission_to_set_review_group: false,
      officeCode: null,
      duration: '',
    },
    report_data = state.viewData[REPORT_DETAIL_DATA_KEY];

  if (
    !!report_data &&
    !report_data.isFetching &&
    !report_data.error &&
    Object.keys(report_data.data).length > 0
  ) {
    for (const key of Object.keys(neededData)) {
      try {
        neededData[key] = report_data.data[key] || neededData[key];
      } catch (e) {
        console.log('Error', e);
      }
    }
  }
  try {
    neededData.officeCode =
      state.viewData[SECTION_DETAIL_DATA_KEY]?.data?.report?.office_code;
  } catch (e) {
    console.log('Error', e);
  }

  const newState = {
    data: {},
    isFetching: false,
    error: null,
    show_error: true,
    hasPermissionToModify:
      report_data?.data?.permissions?.has_permission_to_set_review_group,
    officeCode: neededData.officeCode,
    duration: neededData.duration,

    report_transition: {
      data: {},
      isFetching: false,
      error: null,
      available_state_transitions: neededData.available_state_transitions,
      current_transition_state: neededData.state,
      ...state.viewData[POST_ACR_REPORTS_STATE_TRANSITION_KEY],
    },
    reviewState: {
      data: {},
      isFetching: false,
      error: null,
      ...state.viewData[GET_ALL_REVIEW_STATE_DATA],
    },
    updateReview: {
      ...state.viewData[UPDATE_REVIEW_STATE_DATA_KEY],
    },
    ...state.viewData[POST_ACR_INFO_BOX_CONTENT_KEY],
  };
  return newState;
};

export const mapDispatchToProps = dispatch => {
  return {
    dispatchStateTransition: (params = {}) => {
      dispatch(postAcrReportStateTransition(params));
    },
  };
};

const ReportHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderComponent);

export default ReportHeader;
