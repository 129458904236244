import { Button } from '@wfp/ui';
import { get } from 'lodash';
import React, { Component } from 'react';
import { Circles } from 'react-loader-spinner';
import { connect } from 'react-redux';

import { getActionAPISource } from 'src/redux/actions';
import { EXPORT_TABLES } from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { getCookie, setCookie, showNotification } from 'src/utils';

const roles = get(window, 'djconfig.userInfo.roles', []);
const isHQAdmin = roles.includes('hq_admin') ? true : false;
const superuser = get(window, 'djconfig.userInfo.superuser', []);
export class ExportTables extends Component {
  constructor() {
    super();
    this.state = {
      requested: false,
      allowed: false,
      file_data: {
        file_date: '',
        file_exists: false,
      },
      to_hide: false,
      show_spinner: false,
    };

    this.getUrl = this.getUrl.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleFileGenerate = this.handleFileGenerate.bind(this);
    this.handleFileLoad = this.handleFileLoad.bind(this);
    this.initCheck = this.initCheck.bind(this);
  }

  getUrl = () => {
    let url = getActionAPISource(EXPORT_TABLES);
    const params = {};
    for (const [key, value] of Object.entries(params)) {
      url = url.replace(new RegExp(key, 'g'), value);
    }
    return url;
  };

  initCheck = () => {
    if (isHQAdmin || superuser) {
      setInterval(() => {
        if (getCookie('excel_requested') === 'yes') {
          apiCallAsync(this.getUrl(), { done: 1 }, 'get').then(response => {
            if (
              response.status === 200 &&
              getCookie('excel_requested') === 'yes'
            ) {
              if (response.data['status'] === 'done') {
                showNotification('New .xlsx file generated!', 'success');
                setCookie('excel_requested', 'no');
                this.setState({ to_hide: false, show_spinner: false });
                this.handleCheck();
              }

              if (response.data['status'] === 'failed') {
                setCookie('excel_requested', 'no');
                this.setState({ to_hide: false, show_spinner: false });
                showNotification('Error on file generation', 'error');
                this.handleCheck();
              }
            }
          });
        }
      }, 10000);
    }
  };

  handleFileGenerate = () => {
    apiCallAsync(this.getUrl(), { generate: 1 }, 'get').then(response => {
      if (response.status === 200) {
        showNotification('New .xlsx file generation initiated', 'warning', {
          autoClose: 5000,
        });
      }
    });
    setCookie('excel_requested', 'yes');
    this.setState({ to_hide: true, show_spinner: true });
    this.setState({
      file_data: {
        file_date: '',
        file_exists: false,
      },
    });
    this.initCheck();
  };

  handleFileLoad = () => {
    apiCallAsync(this.getUrl(), { load: 1 }, 'get').then(response => {
      if (response.status === 200) {
        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };
        const blob = b64toBlob(
          response.data.file_data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        );
        const filename = response.data.filename;
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const downloadLink = window.document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          );
          downloadLink.download = filename;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
    });
  };

  handleCheck = () => {
    apiCallAsync(this.getUrl(), {}, 'get').then(response => {
      if (response.status === 200) {
        let allowed = false;
        if (response.data.superuser || response.data.hq_admin) {
          allowed = true;
        }
        let showspinner = false;
        if (
          response.data.user_status === 'requested' &&
          !response.data.file_data.file_exists
        ) {
          allowed = false;
          showspinner = true;
          setCookie('excel_requested', 'yes');
          this.initCheck();
        }
        this.setState({
          file_data: response.data.file_data,
          requested: true,
          allowed: allowed,
          show_spinner: showspinner,
        });
      }
    });
  };

  render() {
    if (!this.state.requested) {
      this.handleCheck();
    }
    const allowed = this.state.allowed;
    const show_spinner = this.state.show_spinner;

    return (
      <div>
        {show_spinner ? (
          <div className="spinner-div">
            <Circles
              color="#085992"
              height={20}
              width={20}
              // timeout={3000} //3 secs
            />
            <p className="spinner-text">Tables .xlsx in progress!</p>
          </div>
        ) : null}
        {allowed ? (
          <div className="acr-dashboard-button">
            {!this.state.to_hide ? (
              <Button
                className="btn btn-default"
                onClick={this.handleFileGenerate}
                data-test-id="generate-tables-button"
              >
                Generate Tables
              </Button>
            ) : null}
            {this.state.file_data.file_exists ? (
              <div>
                <Button
                  className="btn btn-default"
                  onClick={this.handleFileLoad}
                  data-test-id="download-tables-button"
                >
                  Download Tables .xlsx
                </Button>
                <p
                  style={{ textAlign: 'center', fontSize: '0.75rem' }}
                  data-test-id="created-date"
                >
                  Created: {this.state.file_data.file_date}
                </p>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const ExportTablesButton = connect()(ExportTables);
export default ExportTablesButton;
