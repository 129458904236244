import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { Button } from '@wfp/ui';
import React, { useCallback, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import Download from 'src/assets/images/download-icon.svg';
import DotsMenu from 'src/assets/images/dots-menu-icon.svg';
import EditUserModal from 'src/components/UsersManagementTemplate/EditUserModal';
import TableMain from 'src/components/TableMain';
import { API_HOST, rolesToLabels } from 'src/constants';
import { getActionAPISource } from 'src/redux/actions';
import { apiCallAsync } from 'src/redux/sagas';
import { showNotification } from 'src/utils';
import ConfirmationModal from 'src/components/ConfirmationModal';
import RestoreUserBtn from './RestoreUserBtn';

import { IOptionValue } from 'src/types';
import ImportUserBtn from './ImportUserBtn';
import AddUserModal from './AddUserModal';
import { debounce, isEqual } from 'lodash';
import OutsideAlerter from 'src/hooks/useOutsideAlerter';

interface IDeleteModal {
  id: string;
  fullName: string;
}

interface ISearchValues {
  name: string;
  role: number;
  office: number;
}

const defaultOptionValue = { value: 0, label: '-/-' };

const initialLatestSearchValues = {
  name: '',
  role: 0,
  office: 0,
};

const UsersManagementTemplate = () => {
  const [allRecords, setAllRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [searchRole, setSearchRole] = useState(0);
  const [searchOffice, setSearchOffice] = useState<number>(0);
  const [roles, setRoles] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableItemsCount, setTableItemsCount] = useState(0);
  const [showUserModal, setShowUserModal] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [offices, setOffices] = useState([]);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showExportLoader, setShowExportLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState<IDeleteModal | null>(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showSecondaryMenu, setShowSecondaryMenu] = useState(false);
  const [latestSearchValues, setLatestSearchValues] = useState<ISearchValues>(
    initialLatestSearchValues,
  );
  const [clonedCurrentPage, setClonedCurrentPage] = useState(1);

  useEffect(() => {
    getRoles();
    getOffices();
  }, []);

  useEffect(() => {
    getList();
  }, [currentPage, pageSize]);

  function handleUserEditClick(e) {
    const identifier = parseInt(e.target.id.split('edit_button_')[1]);
    getOffices();

    setTargetId(identifier);
    setShowUserModal(true);
  }

  function handleDeleteUser(identifier: string) {
    const url = `${API_HOST}/api/users/${identifier}`;
    apiCallAsync(url, {}, 'delete')
      .then(response => {
        if (response.status === 200) {
          showNotification('User role removed', 'success');
          getList();
        }
      })
      .catch(error => {
        if (error.response) {
          showNotification(
            `${error.message}: ${error.response.data.detail}`,
            'error',
          );
        }
      });
  }

  function selectUserById(targetIdVal: number) {
    const user_data = tableData.filter(obj => {
      return obj.id === targetIdVal;
    });
    return user_data[0];
  }

  function buildRequest() {
    const areValuesChanged = !isEqual(
      {
        name: searchName,
        role: searchRole,
        office: searchOffice,
      },
      latestSearchValues,
    );

    const newCurrentPage = areValuesChanged ? 1 : currentPage;

    setClonedCurrentPage(newCurrentPage);

    let url = `${API_HOST}/api/users/?page=${newCurrentPage}&page_size=${pageSize}`;
    let extra = '';
    if (searchName !== '') {
      extra += `&search_name=${searchName}`;
    }
    if (searchRole !== 0) {
      extra += `&search_role=${searchRole}`;
    }
    if (searchOffice !== 0) {
      extra += `&search_office=${searchOffice}`;
    }
    if (extra !== '') {
      url += `${extra}`;
    }
    return url;
  }

  async function suggestUsers(partialName: string) {
    const url = `${getActionAPISource(
      'USERS_MANAGEMENT_ACTUAL',
    )}?last_name=${partialName.toLowerCase()}`;

    await apiCallAsync(url, {}, 'get').then(response => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          const tmp = response.data.map(row => {
            return { label: row.login_name, value: row.login_name };
          });
          setNameSuggestions(tmp);
        }
      }
    });
  }

  async function loadXlsx() {
    const url = getActionAPISource('EXPORT_USERS');

    try {
      await apiCallAsync(url, {}, 'get').then(result => {
        if (result.status === 200) {
          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += sliceSize
            ) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };
          const blob = b64toBlob(
            result.data.file_data,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          );
          const filename = result.data.filename;
          if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(blob, filename);
          } else {
            const downloadLink = window.document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(
              new Blob([blob], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }),
            );
            downloadLink.download = filename;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            setShowExportLoader(false);
            showNotification('Export users completed', 'success', {
              autoClose: false,
            });
          }
        }
      });
    } catch (e) {
      setShowExportLoader(false);
      showNotification(
        'Error while exporting users. Please retry later',
        'error',
        { autoClose: false },
      );
    }
  }

  function getUsersXlsx() {
    setShowSecondaryMenu(false);
    showNotification('Exporting users', 'info', {
      autoClose: false,
    });
    setShowExportLoader(true);
    loadXlsx();
  }

  async function getList() {
    setLoading(true);

    const url = buildRequest();

    await apiCallAsync(url, {}, 'get').then(result => {
      setTableData(result.data.results);
      setTableItemsCount(result.data.count);
      if (pageSize && result.data.count) {
        setAllRecords(Math.floor(result.data.count / pageSize));
      }
      setLoading(false);
    });
  }

  async function getRoles() {
    const rolesUrl = `${API_HOST}/api/roles/`;

    await apiCallAsync(rolesUrl, {}, 'get').then(result => {
      let resRoles = result.data;
      const rolesList = resRoles.map(({ label, value }) => ({
        label: rolesToLabels?.[label],
        value,
      }));

      resRoles = [defaultOptionValue].concat(rolesList);

      setRoles(resRoles);
    });
  }

  async function getOffices() {
    if (offices.length === 0) {
      const url = `${API_HOST}/api/offices/`;
      apiCallAsync(url).then(result => {
        let resOffices = result.data;
        resOffices = [defaultOptionValue].concat(resOffices);
        setOffices(resOffices);
      });
    }
  }

  function handleSearch(e) {
    e.preventDefault();

    setLatestSearchValues({
      name: searchName,
      role: searchRole,
      office: searchOffice,
    });

    setNameSuggestions([]);
    getList();
  }

  function handleNameChange(val = '') {
    let newSearchName = '';
    if (val) newSearchName = val;

    if (!!newSearchName && newSearchName.length >= 3) {
      suggestUsers(newSearchName);
    }
    setSearchName(newSearchName);
  }

  function handleSelectRole(searchRole: IOptionValue) {
    const value = searchRole.value;
    setSearchRole(value);
  }

  const baseTableColumns = [
    {
      width: 100,
      Header: 'Name',
      id: 'user_info_column',
      Cell: ({ row }) => (
        <div data-test-id="user-data">
          <p data-test-id="first-name">
            {row.original.first_name} {row.original.last_name}
          </p>
          <p data-test-id="username">{row.original.username}</p>
        </div>
      ),
    },
    {
      width: 100,
      Header: 'Email',
      id: 'email',
      Cell: ({ row }) => {
        return <div data-test-id="email">{row.original.email}</div>;
      },
    },
    {
      Header: 'Active Roles',
      id: 'role',
      width: 100,
      Cell: ({ row }) => (
        <div data-test-id="user-role" className="users-manage-table-roles-cell">
          {row.original.roles.map(role => {
            if (!role) return <></>;
            for (let key in role) {
              return (
                <div className="users-manage-table-roles" key={key}>
                  <div className="users-manage-table-roles-title">
                    {rolesToLabels[key]}
                  </div>
                  <div className="users-manage-table-roles-content">
                    {role[key].join(', ')}
                  </div>
                </div>
              );
            }
          })}
        </div>
      ),
    },
    {
      width: 70,
      Header: 'Actions',
      id: 'user_actions_column',
      Cell: ({ row }) => (
        <div className="cell-center">
          <Button
            value={row.original.name}
            onClick={handleUserEditClick}
            key={`edit_${row.original.id}`}
            id={`edit_button_${row.original.id}`}
            data-test-id="edit-button"
            kind="ghost"
          >
            Edit
          </Button>
          <Button
            kind="ghost"
            value={row.original.name}
            onClick={() =>
              setDeleteModal({
                id: row.original.id,
                fullName: `${row.original.first_name} ${row.original.last_name}`,
              })
            }
            key={`delete_${row.original.id}`}
            data-test-id="delete-button"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  let options = [];
  if (nameSuggestions) {
    options = nameSuggestions.map(option => option.value);
  }

  const handlerNameChange = useCallback(debounce(handleNameChange, 300), []);

  return (
    <div className="reports-list">
      <div className="users-manage-header">
        <div className="row">
          <h1>Users Management</h1>
        </div>
        <div className="users-manage-btns">
          <Button
            onClick={() => setShowAddUserModal(true)}
            className="add-user-btn"
          >
            Add New User
          </Button>
          <OutsideAlerter callback={() => setShowSecondaryMenu(false)}>
            <Button
              kind="secondary"
              className="secondary-menu-btn"
              onClick={() => setShowSecondaryMenu(!showSecondaryMenu)}
            >
              <img src={DotsMenu} />
            </Button>

            <div
              className="secondary-menu"
              style={{ display: showSecondaryMenu ? 'block' : 'none' }}
            >
              <ImportUserBtn
                closeMenu={() => setShowSecondaryMenu(false)}
                fetchData={getList}
              />
              <div
                className={`secondary-menu-item export-users-btn ${
                  showExportLoader ? 'disabled' : ''
                }`}
                onClick={getUsersXlsx}
              >
                <img src={Download} />
                Export Users
              </div>
              <RestoreUserBtn
                closeMenu={() => setShowSecondaryMenu(false)}
                fetchData={getList}
              />
            </div>
          </OutsideAlerter>
        </div>
      </div>

      <div className="line users-manage-filters">
        <div className="filter-line suggest">
          <label htmlFor="name_suggest" className="wfp--label">
            User
          </label>
          <Autocomplete
            freeSolo
            className="users-manage-autocomplete"
            disableClearable={searchName === ''}
            options={options}
            onInputChange={(_, val) => handlerNameChange(val)}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  margin="normal"
                  variant="outlined"
                  placeholder="Search user name"
                />
              );
            }}
          />
        </div>
        <div className="filter-line">
          <div className="wfp--form-item">
            <label htmlFor="role-select" className="wfp--label">
              Role
            </label>
            <ReactSelect
              className="wfp--react-select-container"
              classNamePrefix="wfp--react-select"
              id="role-select"
              options={roles}
              defaultValue={defaultOptionValue}
              onChange={handleSelectRole}
            />
          </div>
        </div>
        <div className="filter-line">
          <div className="wfp--form-item">
            <label htmlFor="office-select" className="wfp--label">
              Office
            </label>

            <ReactSelect
              className="wfp--react-select-container"
              classNamePrefix="wfp--react-select"
              id="office-select"
              options={offices}
              defaultValue={defaultOptionValue}
              onChange={(event: IOptionValue) => {
                setSearchOffice(event.value);
              }}
            />
          </div>
        </div>
        <div className="filter-line-button button-pad">
          <Button
            onClick={handleSearch}
            data-test-id="search-button"
            kind="secondary"
          >
            Search
          </Button>
        </div>
      </div>
      <TableMain
        data={tableData}
        loading={loading}
        columns={baseTableColumns}
        className="-striped -highlight users-manage-table"
        pagination="server"
        pageCount={allRecords ? allRecords + 2 : 0}
        totalItems={tableItemsCount}
        fetchData={({ pageSize: pageSizeVal, pageIndex }) => {
          setPageSize(pageSizeVal);
          setCurrentPage(pageIndex || 1);
        }}
        currentPage={clonedCurrentPage}
      />
      {targetId && (
        <EditUserModal
          open={showUserModal}
          onClose={() => {
            setShowUserModal(false);
            setTargetId(null);
            getList();
          }}
          userData={selectUserById(targetId)}
          roles={roles}
          offices={offices}
        />
      )}
      {showAddUserModal && (
        <AddUserModal
          open={showAddUserModal}
          onClose={() => {
            setShowAddUserModal(false);
            getList();
          }}
          roles={roles}
          offices={offices}
        />
      )}
      {!!deleteModal && (
        <ConfirmationModal
          open
          onSubmit={() => {
            handleDeleteUser(deleteModal.id);
            setDeleteModal(null);
          }}
          onClose={() => {
            setDeleteModal(null);
          }}
          heading={`Are you sure you want to delete user ${deleteModal.fullName}?`}
          customClassName="delete-user-modal"
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
        />
      )}
    </div>
  );
};

export default UsersManagementTemplate;
